<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">

                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="昵称" prop="nickname">
                            <el-input v-model="query.nickname" placeholder="请输入昵称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="手机号" prop="mobile">
                            <el-input v-model="query.mobile" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="用户类型" prop="type">
                            <el-select v-model="query.type" placeholder="用户类型"
                                       style="width:100%;">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="网吧员工" :value="0"></el-option>
                                <el-option label="普通用户" :value="1"></el-option>
                                <el-option label="(非支付)网吧员工" :value="3"></el-option>
                                <el-option label="管理员" :value="4"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="网吧用户" prop="shopId">
                            <el-select v-model="query.shopId" placeholder="请输入网吧名称" filterable
                                       :remote-method="remoteMethod"
                                       :loading="loading"
                                       style="width:100%;">
                                <el-option :label="item.name" :value="item.id" :key="item.id"
                                           v-for="item in shopOptions"></el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                            <el-button type="primary" plain @click="openHistoryDialog">
                                <i class="cw-icon cw-icon-zhongzhi"></i>充值记录
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <el-table
                    el-table
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="nickname"
                        label="昵称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        label="手机号"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>

                <el-table-column
                        prop="avatar"
                        label="头像"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-avatar :size="30" :src="scope.row.avatar"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="用户类型"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type===0" type="info">网吧员工</el-tag>
                        <el-tag v-if="scope.row.type===1" type="primary">普通用户</el-tag>
                        <el-tag v-if="scope.row.type===3" type="primary">(非支付)网吧员工</el-tag>
                        <el-tag v-if="scope.row.type===4" type="primary">管理员</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.state==-1">停用</el-tag>
                        <el-tag type="success" v-if="scope.row.state==1">启用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="所属网吧"
                >
                    <template slot-scope="scope">
                        {{scope.row.shopName || '--'}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="registerTime"
                        label="注册时间"
                >
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="95"
                        label="操作">
                    <template slot-scope="scope">
                        <el-dropdown @command="handleCommand">
                            <el-button class="el-dropdown-link" size="mini" type="primary">
                                操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:1,row:scope.row}">修改</el-dropdown-item>
                                <el-dropdown-item :command="{type:2,row:scope.row}">用户信息</el-dropdown-item>
                                <el-dropdown-item :command="{type:3,row:scope.row}">充值记录</el-dropdown-item>
                                <el-dropdown-item :command="{type:4,row:scope.row}">充值</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <el-dialog
                title="修改用户信息"
                width="480px"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="editDialog"
                :before-close="handleClose">
            <edit v-if="editDialog" :row="row" @success="handleClose(1)" @cancel="handleClose"/>
        </el-dialog>
        <el-dialog
                title="查看用户信息"
                fullscreen
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="baseInfoDialog"
                :before-close="handleClose">
            <base-info v-if="baseInfoDialog" :row="row" @success="handleClose(1)" @cancel="handleClose"/>
        </el-dialog>
        <el-dialog
                title="充值记录"
                fullscreen
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="chargeLogsDialog"
                :before-close="handleClose">
            <charge-logs v-if="chargeLogsDialog" :row="row" @success="handleClose()" @cancel="handleClose"/>
        </el-dialog>
        <el-dialog
                title="充值记录"
                fullscreen
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="chargeAllLogsDialog"
                :before-close="handleClose">
            <charge-all-logs v-if="chargeAllLogsDialog" @success="handleClose()" @cancel="handleClose"/>
        </el-dialog>
        <el-dialog
                width="500px"
                title="充值"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="reChargeDialog"
                :before-close="handleClose">
            <re-charge v-if="reChargeDialog" :row="row" @success="handleClose()" @cancel="handleClose"/>
        </el-dialog>
    </el-container>
</template>

<script>
import {mapGetters} from 'vuex'
import request from '@/utils/request'

import {searchUserData, editDo} from '@/api/appUser'
import edit from "@/views/userManage/components/edit";
import baseInfo from "@/views/userManage/components/baseInfo";
import chargeLogs from "@/views/userManage/components/chargeLogs";
import chargeAllLogs from "@/views/userManage/components/chargeAllLogs";
import reCharge from '@/views/userManage/components/reCharge';
import _ from 'loadsh'
import {shopData} from "@/api/firmManage";

export default {
    name: "companyManagement",
    components: {edit, baseInfo, chargeLogs, chargeAllLogs, reCharge},
    data() {
        return {
            tableData: [],
            loading: false,
            pageInfo: {
                totalCount: 0,
                pageNumber: 1,
                pageSize: 10,
            },
            query: {
                searchName: '',
                searchKeyword: ''
            },
            shopOptions: [],
            row: {},
            editDialog: false,
            baseInfoDialog: false,
            chargeLogsDialog: false,
            chargeAllLogsDialog: false,
            reChargeDialog: false
        }
    },
    computed: {
        ...mapGetters([
            'userInfo'
        ]),
    },
    methods: {
        async remoteMethod(val) {
            try {
                let formData = {

                    pageNumber: 1,
                    pageSize: 10
                }
                if (val) {
                    formData.name = val
                }
                let {data} = await shopData(formData)
                this.shopOptions = data.list
                console.log('shop', data)
            } catch (e) {
                console.log(e)
            }
        },
        handleClose(type) {
            if (type === 1) {
                this.handleSearch();
            }
            this.baseInfoDialog = false;
            this.editDialog = false;
            this.chargeLogsDialog = false;
            this.chargeAllLogsDialog = false;
            this.reChargeDialog = false;
        },
        async openHistoryDialog() {
            this.row = {}
            this.chargeAllLogsDialog = true
        },
        async handleCommand(command) {
            this.row = _.cloneDeep(command.row)
            switch (command.type) {
                case 1:
                    this.editDialog = true;
                    break;
                case 2:
                    this.baseInfoDialog = true;
                    break;
                case 3:
                    this.chargeLogsDialog = true;
                    break;
                case 4:
                    this.reChargeDialog = true;
                    // try {
                    //     let {value} = await this.$prompt('请输入充值金额', '提示', {
                    //         confirmButtonText: '确定',
                    //         cancelButtonText: '取消',
                    //         inputPattern: /^[0-9]+(.[0-9]{2})?$/,
                    //         inputErrorMessage: '请输入充值金额'
                    //     })
                    //     console.log(value)
                    //     await request({
                    //         url: '/platform/food/account/rechargeAccont',
                    //         method: 'post',
                    //         data: {
                    //             userId: command.row.id,
                    //             amount: value,
                    //             type: command.row.type
                    //         }
                    //     })
                    //     this.$message.success('充值成功');
                    //
                    // } catch (e) {
                    //     console.error(e)
                    //     this.$message({
                    //         type: 'info',
                    //         message: '取消充值'
                    //     });
                    // }

                    break
            }
        },
        handleSearch() {
            this.pageInfo.pageNumber = 1;
            this.searchUserData()
        },
        async searchUserData() {
            try {
                let query = Object.assign({}, this.pageInfo, this.query)
                let formData = {}
                for (let i in query) {
                    if (query[i] !== '' && i !== 'total' && query[i] !== -1 && i !== 'totalCount') {
                        formData[i] = query[i]
                    }
                }
                let resonse = await searchUserData(formData)
                let {data} = resonse;
                data.list = data.list.map(item => {
                    item.type = +item.type
                    return item
                })
                this.tableData = data.list;
                this.pageInfo.totalCount = data.totalCount;
            } catch (e) {
                console.log(e)
            }
        },
        clearSearch() {
            this.query = {}
            this.handleSearch()
        },
        closeModul() {
            this.visibleFormDialog = false;
            this.searchUserData();
        },
        handleSizeChange(val) {
            this.pageInfo.pageSize = val
            this.pageInfo.page = 1
            this.searchUserData()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNumber = val
            this.searchUserData()
        },
        handleEdit(row) {
            console.log(428, row);
            this.formData = row;
            this.visibleFormDialog = true
        },
        async doEdit() {
            this.$refs['editForm'].validate(async (valid) => {
                if (valid) {
                    try {
                        await editDo(this.formData);
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.visibleFormDialog = false
                        this.searchUserData()
                    } catch (e) {
                    }
                }
            })

        },

    },
    mounted() {
        this.searchUserData();
        this.remoteMethod()
    }
}
</script>

<style lang="less">
.btn-group {
    button {
        margin-left: 20px;
    }

}

.el-input--prefix .el-input__inner {
    padding-left: 46px;
}

.el-input__prefix {
    left: 15px;
    color: #303133;

    i {
        font-style: normal;
    }
}

.icon-cursor {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
        color: #348EED;
    }
}

</style>
