<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form ref="searchForm" :model="query" label-width="80px">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <el-form-item prop="name" label="充值时间">
                            <el-date-picker
                                    class="inner-input"
                                    v-model="query.date"
                                    type="daterange"
                                    value-format="timestamp"
                                    clearable
                                    range-separator="至"
                                    :default-time="['00:00:00', '23:59:59']"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <el-form-item label-width="0">
                            <el-button type="primary" size="medium" @click="getData()">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table
                    border
                    style="width: 100%"
                    :data="result.list"
            >


                <el-table-column label="头像">
                    <template slot-scope="scope">
                        <el-image
                                style="width:48px; height: 48px"
                                :src="scope.row.avatar"
                                :preview-src-list="[scope.row.avatar]">
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column label="昵称" prop="nickname">
                </el-table-column>
                <el-table-column label="充值金额" prop="amountOfRecharge">
                </el-table-column>
                <el-table-column label="赠送金额" prop="giftAmount"></el-table-column>
                <el-table-column label="充值时间" prop="createTime"></el-table-column>
                <el-table-column label="充值方式" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.logType===1" type="info">普通充值</el-tag>
                        <el-tag v-if="scope.row.logType===2" type="primary">会员充值</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="充值状态" prop="payStatus">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.payStatus===4" type="info">撤销充值</el-tag>
                        <el-tag v-if="scope.row.payStatus===2" type="success">充值成功</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="充值人" prop="loginname"></el-table-column>
                <el-table-column fixed="right"
                                 width="95"
                                 label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" v-if="!scope.row.wxPayNo&&scope.row.payStatus!==4" size="mini"
                                   @click="handleCancelCharge(scope.row)">撤销充值
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="pageSizeChange"
                    @current-change="getData"
                    :current-page="query.pageNo"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="query.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="result.totalCount">
            </el-pagination>
        </el-main>

    </el-container>

</template>

<script>
import request from '@/utils/request'
import * as foodManager from "@/api/foodManager";

export default {
    name: "chargeLogs",
    data() {
        return {

            query: {
                date: [],
                pageSize: 10
            },
            result: {
                list: [],
                totalCount: 0
            },

        }
    },

    methods: {
        async handleCancelCharge(row) {
            try {
                await this.$confirm('删除后无法恢复，确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                let formData = {
                    logId: row.id
                }
                let data = await request({
                    url: '/platform/food/account/cancelRecharge',
                    method: 'post',
                    data: formData
                })
                this.$message({
                    message: data.msg,
                    type: 'success'
                });
                this.getData();
            } catch (e) {

            }
        },
        async getData(page) {
            try {
                let formData = {}
                if (this.query.date && this.query.date.length) {
                    this.query.start = this.query.date[0]
                    this.query.end = this.query.date[1]
                } else {
                    this.query.start = ''
                    this.query.end = ''
                }
                this.query.pageNo = page || 1
                for (let key in this.query) {
                    if (this.query[key] && key !== 'date') {
                        formData[key] = this.query[key]
                    }
                }

                let {data} = await request({
                    url: '/platform/app/user/account/records/data',
                    methods: 'get',
                    data: formData
                })
                this.result = data
            } catch (e) {

            }
        },
        pageSizeChange(val) {//分页大小更新操作
            this.query.pageSize = val;
            this.getData();
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
</style>
