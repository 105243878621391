import request from '../utils/request'

/*
*==============会员列表
* @params{
* */

export function searchUserData(data) {
    return request({
        url: '/platform/app/user/data',
        method: 'post',
        data
    })
}

/*
* ============导出会员信息
* */
export function editDo(data) {
    return request({
        url: '/platform/app/user/editDo',
        method: 'post',
        data
    })
}
//充值
export function rechargeAccont(data) {
    return request({
        url: '/platform/food/account/rechargeAccont',
        method: 'post',
        data
    })
}
