import request from '../utils/request'
 /*
 * ============新增账户
 * @params{
 *
 * addAccountVo:{
 *      bankaddress: string      开户行
 *      bankname:string          银行名称
 *      name:string              姓名
 *      no:string                账号
 *      type:init                充值类型
 *      status:init              状态
 *     }
 * }
 * */
 //查询
export function shopData(data) {
    return request({
        url:'/platform/food/shop/data',
        method:'post',
        data
    })
}
//添加
export function shopAdd(data) {
    return request({
        url:'/platform/food/shop/addDo',
        method:'post',
        data
    })
}
//修改
export function shopEdit(data) {
    return request({
        url:'/platform/food/shop/editDo',
        method:'post',
        data
    })
}
//删除一个
export function shopDeleteOne(data) {
    return request({
        url:'/platform/food/shop/delete/'+data,
        method:'post',
    })
}
//删除多个
export function shopDeleteMore(data) {
    return request({
        url:'/platform/food/shop/delete',
        method:'post',
        data
    })
}
//获取小程序码
export function getErcode(data) {
    return request({
        url:'/platform/food/shop/getErcode/'+data,
        method:'post',
    })
}
//商家返点
export function getReward(data) {
    return request({
        url:'/platform/food/reward/detail/data',
        method:'get',
        data
    })
}
/*
*==============账户列表
* @params{
* searchAccountVo:{    查询条件
*   name:string    姓名
*   no:string      账户
*   status:init    状态
*   type:init      类型
* }
* searchByPageVo:{
*    page:init
*    rows:init
* }
* */

/*
* ============更新账户
* @params{
* updateAccountVo:{
*   bankaddress: string      开户行
*   bankname:string          银行名称
*   name:string              姓名
*   no:string                账号
*   type:init                充值类型
*   status:init              状态
*   }
* }
* */

