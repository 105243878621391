<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <el-card>
                <div slot="header" class="card-header">
                    <span>用户基本信息</span>
                    <el-image
                            style="width: 48px; height: 48px;border-radius: 48px"
                            :src="result.avatar"
                            :preview-src-list="[result.avatar]">
                    </el-image>
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                昵称
                            </div>
                            <div class="text">
                                {{result.nickname}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                姓名
                            </div>
                            <div class="text">
                                {{result.name}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                手机号
                            </div>
                            <div class="text">
                                {{result.mobile}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                用户类型
                            </div>
                            <div class="text">
                                <el-tag size="mini" v-if="result.type===1" type="info">普通用户</el-tag>
                                <el-tag size="mini" v-if="result.type===2" type="primary">会员用户</el-tag>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                生日
                            </div>
                            <div class="text">
                                {{result.birthday}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                性别
                            </div>
                            <div class="text">
                                {{result.gender == '1' ? '男' : '女'}}
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                账号状态
                            </div>
                            <div class="text">
                                <el-tag size="mini" type="danger" v-if="result.state==-1">停用</el-tag>
                                <el-tag size="mini" type="success" v-if="result.state==1">启用</el-tag>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="col-item">
                            <div class="label">
                                注册时间
                            </div>
                            <div class="text">
                                {{result.registerTime}}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="col-item">
                            <div class="label">
                                住址
                            </div>
                            <div class="text">
                                {{result.address}}{{result.numberPlate}}
                            </div>
                        </div>
                    </el-col>

                </el-row>
            </el-card>
            <br>
            <el-card v-if="result.account">
                <div slot="header" class="card-header">
                    <span>账户信息</span>
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                账户余额
                            </div>
                            <div class="text">
                                <el-tag size="mini">{{result.account.accountAmount}}</el-tag>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="col-item">
                            <div class="label">
                                使用金额
                            </div>
                            <div class="text">
                                <el-tag size="mini" type="info">{{result.account.useAmount}}</el-tag>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </el-main>

    </el-container>

</template>

<script>
import request from '@/utils/request'

export default {
    name: "baseInfo",
    props: {
        row: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            result: {}
        }
    },

    methods: {
        async getData() {
            try {
                let {data} = await request({url: 'platform/app/user/account', method: 'post', data: {id: this.row.id}})
                this.result = data
            } catch (e) {

            }
        },

    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
.card-header {
    display: flex;
    align-items: center;
}

.col-item {
    display: flex;
    align-items: center;
    padding: 10px;

    .label {
        width: 80px;
        flex-shrink: 0;
        color: #969696;
    }

    .text {
        flex: 1;
        color: #363636;
    }
}
</style>
