<template>
    <el-container>
        <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="80px" style="width: 100%">
            <el-form-item prop="amount" label="充值金额">
                <el-input maxlength="100" placeholder="充值金额"
                          v-model="formData.amount"
                          auto-complete="off" tabindex="4" type="text" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item prop="password" label="支付密码">
                <el-input placeholder="支付密码"
                          v-model="formData.password"
                          auto-complete="off" tabindex="4" type="password" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item >
                <el-button type="primary" :loading="loading" @click="handleSubmit">确 定</el-button>
                <el-button @click="handleCancel">取 消</el-button>
            </el-form-item>

        </el-form>
    </el-container>

</template>

<script>
import {rechargeAccont} from "@/api/appUser";

export default {
    name: "edit",
    props: {
        row: {
            type: Object,
            default: {}
        }
    },
    data() {
      let validateamount = (rule, value, callback) => {
        if(!value){
          callback(new Error('请输入最多两位小数充值金额'));
        }else if ((!(/^[0-9]+(.[0-9]{0,2})?$/.test(value)))) {
          callback(new Error('请输入最多两位小数充值金额'));
        } else {
          callback();
        }
      };
        return {
            loading: false,
            formData: {},
            formRules: {
                amount: [
                    {required: true,message: '请输入最多两位小数充值金额',validator:validateamount, trigger: 'blur'}
                ],
                password: [
                    {required: true,message: '请输入6-20位支付密码', trigger: 'blur',min:6,max:20}
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs["editForm"].validate(async (valid) => {
                if (valid) {//表单验证通过
                    try {
                        this.loading = true
                        let data = await rechargeAccont(this.formData);
                        console.log(data)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.loading = false
                        this.$emit('success')
                    } catch (e) {
                        this.loading = false
                        console.error(e)
                    }
                }
            });
        },
        handleCancel() {
            this.$emit('cancel')
        }
    },
    mounted() {
        this.formData.userId = this.row.id;
        this.formData.type=1;
    }
}
</script>

<style scoped>

</style>
