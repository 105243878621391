<template>
    <el-container>
        <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="80px">
            <el-form-item prop="nickname" label="昵称">
                <el-input maxlength="100" placeholder=""
                          v-model="formData.nickname" disabled
                          auto-complete="off" tabindex="4" type="text"></el-input>
            </el-form-item>
            <el-form-item prop="mobile" label="手机号">
                <el-input maxlength="100" placeholder=""
                          v-model="formData.mobile" disabled
                          auto-complete="off" tabindex="4" type="text"></el-input>
            </el-form-item>
            <el-form-item prop="avatar" label="头像">
                <el-avatar :size="50" :src="formData.avatar"></el-avatar>
            </el-form-item>
            <el-form-item prop="type" label="用户类型">
                <el-radio-group v-model="formData.type">
                    <el-radio :label="1" size="mini" border style="margin-right: 0">普通用户</el-radio>
                    <el-radio :label="3" size="mini" border style="margin-right: 0">(非支付)网吧员工</el-radio>
                    <el-radio :label="4" size="mini" border style="margin-right: 0">管理员</el-radio>
                    <el-radio :label="0" size="mini" border style="margin-right: 0">网吧员工</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="shopId" label="网吧名称" v-if="formData.type==3">
                <el-select v-model="formData.shopId" placeholder="网吧名称" style="width: 100%;">
                    <el-option v-for="(item,index) in shopDataArr" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="state" label="状态">
                <el-radio-group v-model="formData.state">
                    <el-radio :label="1" border size="mini" style="margin-right: 0">正常</el-radio>
                    <el-radio :label="-1" border size="mini" style="margin-right: 0">停用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item >
                <el-button type="primary" :loading="loading" @click="handleSubmit">确 定</el-button>
                <el-button @click="handleCancel">取 消</el-button>
            </el-form-item>

        </el-form>
    </el-container>

</template>

<script>
import {editDo} from "@/api/appUser";

export default {
    name: "edit",
    props: {
        row: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            loading: false,
            formData: {},
            formRules: {
                nickname: [
                    {message: '昵称', trigger: 'blur'}
                ],
                mobile: [
                    {message: '手机号', trigger: 'blur'}
                ],
                avatar: [
                    {message: '头像', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '用户类型', trigger: 'blur'}
                ],
                state: [
                    {required: true, message: '状态', trigger: 'blur'}
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs["editForm"].validate(async (valid) => {
                if (valid) {//表单验证通过
                    try {
                        this.loading = true
                        let data = await editDo(this.formData);
                        console.log(data)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.loading = false
                        this.$emit('success')
                    } catch (e) {
                        this.loading = false
                        console.error(e)
                    }
                }
            });
        },
        handleCancel() {
            this.$emit('cancel')
        }
    },
    mounted() {
        this.formData = this.row;
    }
}
</script>

<style scoped>

</style>
